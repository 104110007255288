import styled from 'styled-components';
import { rgba } from '../../../helpers';

const Button = styled.button`
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  backface-visibility: hidden;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  display: inline-block;
  border: 0.125rem solid transparent;
  background: ${({ bg }) => bg || '#009EE0'};
  color: ${({ textColor }) => textColor || '#fff'};

  ${({ large }) =>
    large &&
    `
    padding: 0.8rem 2rem;
  `}

  ${({ cta }) =>
    cta &&
    `
    padding: 0.6rem 3rem;
  `}

  &:hover {
    background: ${({ bg }) => rgba(bg || '#009EE0', 0.8)};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #ababab;
  }
`;

export default Button;

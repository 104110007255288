import styled from 'styled-components';

export const Links = styled.div`
  a {
    color: #252525;
    text-decoration: none;
    font-size: 11pt;

    &:nth-child(7) {
      color: #fff;
      font-size: 9pt;
      text-align: center;
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
        @media (max-width: 960px) {
            display: none;
        }

        a {
            margin-right: 1rem;

            &:last-child {
                margin-right: unset;
            }
        }
    `
      : `
        padding: 3rem;
        display: flex;
        flex-direction: column;

        a {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: unset;
            }
        }
    `}
`;

export const Language = styled.button`
  background: none;
  border: none;

  img {
    margin-left: 0.2rem;
    vertical-align: middle;
    margin-top: -0.2rem;
  }
`;

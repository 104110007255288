/* eslint-disable no-restricted-syntax */
const hexToRgb = (hex) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const localHex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b,
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    localHex,
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgba = (hex, alpha) => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export function sortByFolderOrder(PloneTree, CustomItems) {
  const { allPloneSite, allPloneFolder } = PloneTree;
  const ids = allPloneSite.edges[0].node.items.map(
    (item) => item._id,
  );
  for (const folder of allPloneFolder.edges) {
    let idx = ids.indexOf(folder.node.id);
    if (idx !== -1) {
      for (const item of folder.node.items) {
        ids.splice(idx + 1, 0, item._id);
        idx += 1;
      }
    } else {
      for (const item of folder.node.items) {
        ids.push(item._id);
      }
    }
  }
  return CustomItems.edges.sort(
    (a, b) => ids.indexOf(a.node.id) - ids.indexOf(b.node.id),
  );
}

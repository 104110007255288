import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin-right: 1rem;

    &:last-child {
      margin-right: unset;
    }

    .icon {
      width: 22px;
      height: 22px;
      vertical-align: -0.125em;
    }
  }
`;

export default Wrapper;

import React from "react";
import Wrapper from "./styles";
import { FormattedMessage } from "react-intl";

const Local = () => (
  <Wrapper>
    <span><FormattedMessage id="footer.local_line" /></span>
  </Wrapper>
);

export default Local;

import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import Flex from './styles';

const Links = ({ lang }) => (
  <Flex>
    <Link to={lang === 'en' ? '/en/' : '/de/'}>
      <FormattedMessage id="footer.home" />
    </Link>
    <Link to={lang === 'en' ? '/en/#news' : '/de/#news'}>
      <FormattedMessage id="footer.status_news" />
    </Link>
    <Link to={lang === 'en' ? '/en/#faq' : '/de/#faq'}>
      <FormattedMessage id="faq_nav" />
    </Link>
    <a alt="Blog" href="https://blog.portknox.net">
      <FormattedMessage id="footer.blog" />
    </a>
    <Link to={lang === 'en' ? '/en/support/' : '/de/support/'}>
      <FormattedMessage id="contact_nav" />
    </Link>
  </Flex>
);

Links.propTypes = {
  lang: propTypes.string,
};

export default Links;

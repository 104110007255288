import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import Flex from './styles';

const Epilogue = ({ lang }) => (
  <Flex>
    <Link
      to={
        lang === 'en'
          ? '/en/privacy-policy/'
          : '/de/datenschutzerklaerung/'
      }
    >
      <FormattedMessage id="footer.privacy_policy" />
    </Link>
    <Link to={lang === 'en' ? '/en/imprint/' : '/de/impressum/'}>
      <FormattedMessage id="footer.imprint" />
    </Link>
  </Flex>
);

Epilogue.propTypes = {
  lang: propTypes.string,
};

export default Epilogue;

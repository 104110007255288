import React from 'react';
import propTypes from 'prop-types';
import NavbarLinks from '../NavbarLinks';
import SidebarContainer from './styles';

const Sidebar = ({ sidebar, toggle, lang }) => (
  <SidebarContainer active={sidebar} onClick={toggle}>
    <NavbarLinks lang={lang} />
  </SidebarContainer>
);

Sidebar.propTypes = {
  sidebar: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  lang: propTypes.string,
};

export default Sidebar;

import React from "react";
import XIcon from "../../../../assets/icons/x.svg";
import BlueskyIcon from "../../../../assets/icons/bluesky.svg";
import FacebookIcon from "../../../../assets/icons/facebook.svg";
import LinkedinIcon from "../../../../assets/icons/linkedin.svg";
import InstagramIcon from "../../../../assets/icons/instagram.svg";
import MastodonIcon from "../../../../assets/icons/mastodon.svg";
import RssIcon from "../../../../assets/icons/rss.svg";
import Wrapper from "./styles";

const Social = () => (
  <Wrapper>
    <a
      href="https://x.com/portknox_net"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={XIcon} alt="X"/>
    </a>
    <a
      href="https://bsky.app/profile/portknox.bsky.social"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={BlueskyIcon} alt="bluesky"/>
    </a>
    <a
      href="https://mastodon.xyz/@portknox"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={MastodonIcon} alt="Mastodon"/>
    </a>
    <a
      href="https://www.facebook.com/PortKnox.net/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={FacebookIcon} alt="facebook"/>
    </a>
    <a
      href="https://www.instagram.com/portknox_net/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={InstagramIcon} alt="instagram"/>
    </a>
    <a
      href="https://www.linkedin.com/company/portknox"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={LinkedinIcon} alt="linkedin"/>
    </a>
    <a
      href="https://portknox.net/rss_updates"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className='icon' src={RssIcon} width={'20px'} height={'20px'} alt="Rss feed"/>
    </a>
  </Wrapper>
);

export default Social;

import React, { useState } from 'react';
import propTypes from 'prop-types';
import Navbar from './Navbar';
import Hamburger from './Hamburger';
import Sidebar from './Sidebar';
import { Wrapper, Overlay } from './styles';

const Header = ({ lang }) => {
  const [sidebar, toggleSidebar] = useState(false);

  const toggle = () => toggleSidebar(!sidebar);

  return (
    <Wrapper>
      <Overlay sidebar={sidebar} onClick={toggle} />
      <Navbar lang={lang} />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} lang={lang} />
    </Wrapper>
  );
};

Header.propTypes = {
  lang: propTypes.string,
};

export default Header;

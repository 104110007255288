import styled, { createGlobalStyle } from 'styled-components';

export const Theme = createGlobalStyle`
  html {
    font-family: 'Roboto', 'rubik', sans-serif;
  !important;
    scrollBehavior: 'smooth';

    body {
      font-size: 18px !important;
      margin: 0;
      padding: 0;

      strong, b {
        font-weight: 500 !important;
      }
    }
    a {
      color: #009ee0;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 7rem;
`;

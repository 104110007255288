import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  span {
    color: #fff;
  }
  a {
    margin-right: 1rem;

    &:last-child {
      margin-right: unset;
    }
  }
`;

export default Wrapper;

import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/Button';
import EngFlag from 'assets/icons/en.svg';
import DeFlag from 'assets/icons/de.svg';
import { Links, Language } from './styles';

const NavbarLinks = ({ desktop, lang }) => (
  <Links desktop={desktop}>
    <Link to={lang === 'en' ? '/en/#features' : '/de/#features'}>
      <FormattedMessage id="features_nav" />
    </Link>
    <Link
      to={lang === 'en' ? '/en/#testimonials' : '/de/#testimonials'}
    >
      <FormattedMessage id="testimonials_nav" />
    </Link>
    <Link to={lang === 'en' ? '/en/#news/' : '/de/#news'}>
      <FormattedMessage id="news_nav" />
    </Link>
    <Link to={lang === 'en' ? '/en/#faq' : '/de/#faq'}>
      <FormattedMessage id="faq_nav" />
    </Link>
    <Link to={lang === 'de' ? '/de/prices' : '/en/prices'}>
      <FormattedMessage id="pricing_nav" />
    </Link>
    <Link to={lang === 'en' ? '/en/support/' : '/de/support/'}>
      <FormattedMessage id="contact_nav" />
    </Link>
    <Button
      bg="#009EE0"
      as={Link}
      to={lang === 'de' ? '/de/signup/' : '/en/signup/'}
    >
      <FormattedMessage id="start_free_trial" />
    </Button>
    <Language as={Link} to={lang === 'de' ? '/en/' : '/de/'}>
      <FormattedMessage
        id={lang === 'en' ? 'language_de' : 'language_en'}
      />
      <img
        width="24"
        src={lang === 'en' ? DeFlag : EngFlag}
        alt="Change language"
      />
    </Language>
  </Links>
);

NavbarLinks.propTypes = {
  desktop: propTypes.bool,
  lang: propTypes.string,
};

export default NavbarLinks;

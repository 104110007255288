import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  a {
    margin-right: 1rem;
    color: #fff;

    &:last-child {
      margin-right: unset;
    }

    @media (max-width: 960px) {
      margin-right: unset;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: unset;
      }
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export default Flex;

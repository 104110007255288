import React from 'react';
import propTypes from 'prop-types';
import { HamburgerIcon, Bar } from './styles';

const Hamburger = ({ sidebar, toggle }) => (
  <HamburgerIcon sidebar={sidebar} onClick={toggle}>
    <Bar top sidebar={sidebar} />
    <Bar mid sidebar={sidebar} />
    <Bar bottom sidebar={sidebar} />
  </HamburgerIcon>
);

Hamburger.propTypes = {
  sidebar: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
};

export default Hamburger;

import React from "react";
import propTypes from "prop-types";
import { Link } from "gatsby";
import Container from "components/common/Container";
import Logo from "components/common/Logo";
import Wrapper from "./styles";
import Links from "./Links";
import Social from "./Social";
import Epilogue from "./Epilogue";
import Local from "./Local";
import { initializeAndTrack } from "@mobile-reality/gatsby-plugin-gdpr-cookies";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router";


const Footer = ({ lang }) => {

  const location = useLocation();
  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <Logo color="#fff"/>
        </Link>
        <Links lang={lang}/>
        <Epilogue/>
        <Social/>
        <Local/>
        <CookieConsent
          buttonText="Alle akzeptieren"
          declineButtonText="Ablehnen"
          buttonStyle={{ background: "#009EE0", color: "#FFF", fontSize: "13px" }}
          declineButtonStyle={{ background: "#009EE0", color: "#FFF", fontSize: "13px" }}
          style={{ background: "#FFF", color: "#000 !important", fontSize: "17px" }}
          onAccept={() => {
            Cookies.set("gatsby-gdpr-google-analytics", "true");
            Cookies.set("gatsby-gdpr-facebook-pixel", "true");
            initializeAndTrack(location);
          }}
          enableDeclineButton
        >
          Wir nutzen Cookies, um unsere Website zu verbessern und Ihnen personalisierte Inhalte zu bieten.
          Weitere Informationen finden Sie in unserer <Link
          to={"/de/datenschutzerklaerung/"}>Datenschutzerklärung.</Link>
        </CookieConsent>
      </Container>
    </Wrapper>
  );
};

Footer.propTypes = {
  lang: propTypes.string
};

export default Footer;

import React from 'react';
import propTypes from 'prop-types';
require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/de') // Add locale data for de
require('@formatjs/intl-pluralrules/locale-data/en') // Add locale data for de
import { IntlProvider } from 'react-intl';
import localEng from 'lang/en.json';
import localDe from 'lang/de.json';
import Header from 'components/theme/Header';
import Footer from 'components/theme/Footer';
import "semantic-ui-css/semantic.min.css";
import '../../../assets/fonts/stylesheet.css';
import './layout.css';
import { Theme, Wrapper } from './global-style';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 800,
    speedAsDuration: true,
    easing: 'easeInOutCubic',
  });
}

const messages = {
  en: localEng,
  de: localDe,
};

const Layout = ({ children, lang = 'de' }) => (
  <IntlProvider locale={lang} messages={messages[lang]}>
    <>
      <Theme />
      <Header lang={lang} />
      <Wrapper>{children}</Wrapper>
      <Footer lang={lang} />
    </>
  </IntlProvider>
);

Layout.propTypes = {
  children: propTypes.node.isRequired,
  lang: propTypes.string,
};

export default Layout;

import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';
import Container from 'components/common/Container';
import Logo from 'components/common/Logo';
import NavbarLinks from '../NavbarLinks';
import { Wrapper, BrandLogo, Brand } from './styles';

const Navbar = ({ lang }) => (
  <Wrapper as={Container}>
    <Brand as={Link} to={lang === 'en' ? '/en/' : '/de/'}>
      <BrandLogo as={Logo} />
    </Brand>
    <NavbarLinks desktop lang={lang} />
  </Wrapper>
);

Navbar.propTypes = {
  lang: propTypes.string,
};

export default Navbar;
